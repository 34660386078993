/**
 *  Very lite javascript/styling for the login page
 */

import styles from './login/style.scss';

styles._insertCss && (styles as any)._insertCss();

// Set up the redirect now before login
let redirectURL = '';
if (window.location.hash) {
    const redirect = window.location.hash.match(/#redirect=(.*)/);
    if (redirect && redirect[1]) {
        redirectURL = atob(redirect[1]);
        console.log('Redirecting to ' + redirectURL);
    }
}


// Auto login if they have existing valid credentials
//     if ('credentials' in navigator) {
//         navigator.credentials.get({ password: true }).then((account: any) => {
//             if (account && account.name && account.password) {
//                 submit(account.name, account.password);
//             }
//         });
//     }
//
// Create the "Signing In..." dot effect
let dots = 0;
let dotterTimer: number;
function dotter(): void {
    dots++;
    if (dots >= 4) {
        dots = 0;
    }
    const button = document.getElementById('submit') as HTMLButtonElement;
    button.value = 'Signing In' + '.'.repeat(dots);
    dotterTimer = setTimeout(dotter, 400);
}

function onFailedAutoLogin() {
    console.log('Failed Login');
    if ('credentials' in navigator) {
        if ('preventSilentAccess' in navigator.credentials) {
            navigator.credentials.preventSilentAccess();
        }
    }
    window.location.reload();
}

function submit(username: string, password: string): void {
    try {
        const button = document.getElementById('submit') as HTMLButtonElement;
        dotter();
        button.disabled = true;
        const xmlhttp = new XMLHttpRequest();
        const data = 'login=' + encodeURIComponent(username) + '&password=' + encodeURIComponent(password) + '&remember_me=on';
        xmlhttp.open('POST', '/login', true);
        xmlhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        const onFail = () => {
            clearTimeout(dotterTimer);
            button.disabled = false;
            button.value = 'Sign In';
        };
        xmlhttp.onerror = onFail;
        xmlhttp.onabort = onFail;
        xmlhttp.ontimeout = onFail;
        xmlhttp.onreadystatechange = () => {
            if( !xmlhttp.responseURL.match(/\/login/)) {
                redirectURL = xmlhttp.responseURL;
            }

            // console.log("xmlhttp state change", xmlhttp, xmlhttp.responseURL.match(/\/login/));
            // asdfasdf();
            /**
             * Failed login with a 204 (No Content) response
             */
            if (xmlhttp.readyState === XMLHttpRequest.DONE && xmlhttp.status !== 200) {
                return onFailedAutoLogin();
            }
            if (xmlhttp.readyState === XMLHttpRequest.DONE && xmlhttp.status === 200) {
                const response = xmlhttp.responseText;
                console.log(xmlhttp, response);
                // Failed login
                // We check if response has login.js, meaning server gave us back /login page
                if (response.indexOf('login.js') >= 0) {
                    return onFailedAutoLogin();
                } else {
                    try {
                        const responseJSON = JSON.parse(response);
                        if (responseJSON.login && responseJSON.login === 'disabled') {
                            // Account is disabled
                            clearTimeout(dotterTimer);
                            button.value = 'Sign In';
                            button.disabled = false;
                            const existingFlash = document.getElementById('disabled-account');
                            if (existingFlash) {
                                existingFlash.remove();
                            }
                            const flash = document.createElement('div');
                            flash.id = 'disabled-account';
                            flash.classList.add('flash');
                            flash.textContent = 'Account is disabled, an administrator has been notified to re-activate your account.';
                            document.getElementsByTagName('fieldset')[0].childNodes[1].appendChild(flash);
                            return;
                        }
                    } catch (e) {
                        // Do nothing
                    }
                    const ls = localStorage.getItem('previous-logins');
                    const previousLogins: IStoredLogin[] = ls ? JSON.parse(ls) : [];
                    if (!previousLogins.find(user => user.email === username)) {
                        previousLogins.push({
                            email: username,
                            initials: '',
                            name: username,
                        });
                        localStorage.setItem('previous-logins', JSON.stringify(previousLogins));
                    }
                    clearTimeout(dotterTimer);
                    button.value = 'Logged In';
                    // TODO: Fix this so firefox can auto login same as chrome
                    if ('credentials' in navigator && global.PasswordCredential) {
                        navigator.credentials
                            .store(
                                new PasswordCredential({
                                    id: username,
                                    name: username,
                                    password,
                                }),
                            )
                            .then(() => {
                                if (redirectURL) {
                                    console.log('Logged in, redirecting');
                                    // window.location.href = redirectURL;
                                    runRouter(redirectURL)
                                } else {
                                    console.log('Logged in, not redirecting');
                                    runRouter(xmlhttp.responseURL)
                                    // window.location.href = xmlhttp.responseURL || '';
                                }
                                // loadLoader();
                            })
                            .catch((e) => {
                                if (redirectURL) {
                                    console.log('Logged in, redirecting');
                                    window.location.href = redirectURL;
                                } else {
                                    console.log('Logged in, not redirecting');
                                    window.location.reload();
                                }
                            });
                    } else {
                        if (redirectURL) {
                            console.log('Logged in, redirecting');
                            // runRouter(redirectURL);
                            window.location.href = redirectURL;

                        } else {
                            console.log('Logged in, not redirecting');
                            // runRouter(xmlhttp.responseURL);
                            window.location.href = xmlhttp.responseURL || '';
                        }
                    }
                }
            }
        };
        xmlhttp.send(data);
    } catch (e) {
        alert(e.toString());
        console.error(e);
    }
}

function restoreLoginScreen(): void {
    const fieldset = document.querySelector('#login-form fieldset') as HTMLElement;
    if (fieldset) {
        fieldset.style.display = '';
        (document.querySelector('#quick-login') as HTMLElement).remove();
        (document.querySelector('#submit') as HTMLElement).style.display = '';
    }
}

function quickLogin(e: React.MouseEvent<HTMLElement>): void {
    const fieldset = document.querySelector('#login-form fieldset') as HTMLElement;
    if (fieldset) {
        fieldset.style.display = '';
        (document.querySelector('#quick-login') as HTMLElement).remove();
        (document.querySelector('#submit') as HTMLElement).style.display = '';
        (document.querySelector('#email') as HTMLInputElement).value = e.currentTarget.dataset.email as string;
    }
}

interface IStoredLogin {
    name: string;
    email: string;
    initials: string;
}

window.addEventListener('load', () => {
    const ls = localStorage.getItem('previous-logins');
    const previousLogins: IStoredLogin[] = ls ? JSON.parse(ls) : [];
    if (previousLogins.length > 1) {
        const fieldset = document.querySelector('#login-form fieldset') as HTMLElement;
        if (fieldset) {
            fieldset.style.display = 'none';
            const loginForm = document.querySelector('#login-form') as HTMLElement;
            (document.querySelector('#submit') as HTMLElement).style.display = 'none';
            const containerDiv = document.createElement('div');
            containerDiv.id = 'quick-login';
            previousLogins.map(user => {
                const div = document.createElement('div');
                div.className = 'user-account';
                div.innerText = user.name;
                div.onclick = quickLogin;
                div.dataset.email = user.email;
                containerDiv.append(div);
            });
            const div = document.createElement('div');
            div.className = 'user-account';
            div.innerText = 'New Login';
            div.onclick = restoreLoginScreen;
            containerDiv.append(div);
            loginForm.prepend(containerDiv);
        }
    }
    document.getElementById('login-form').addEventListener('submit', e => {
        e.preventDefault();
        try {
            const username = document.getElementById('email')?.value;
            const password = document.getElementById('password')?.value;
            if (username && password) {
                submit(username, password);
            }
        } catch (e2) {
            alert(e2.toString());
        }
        return false;
    });
    // loadRouter();
});

// let loadRouter = function loadRouterOnce() {
//     return import('./loader').then((l) => {
//         console.debug("Loader loaded", l);
//         loadRouter = () => l.default.router;
//         return l.default.router;
//     }).then((r) => {
//         console.debug("Router loaded", r);
//         return r;
//     });
// };
//
// function runRouter(url: string) {
//      if(url) {
//          history.pushState(null, "", url);
//      }
//
//     loadRouter().then((r) => {
//         r.renderRouterApp();
//     })
// }
